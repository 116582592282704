import React, {useRef, useState} from 'react';
import AppLayout from "../../components/Layouts/App/App";
import {ActionType, ProCard, ProColumns, ProTable} from "@ant-design/pro-components";
import {Button, Dropdown, message, Modal} from "antd";
import {EllipsisOutlined, ExclamationCircleOutlined, FileExcelOutlined, PlusOutlined} from "@ant-design/icons";
import {Order} from "./models/Order";
import {OrderService} from "./services/OrderService";
import GenerateOrderReportModal from "./components/GenerateOrderReportModal";
import moment from "moment";
import * as XLSX from "xlsx";
import {ContextMenuSchema} from "../../models/ContextMenuSchema";
import ContextMenu from "./components/ContextMenu";
import HistoryDrawer from "./components/HistoryDrawer";
import {useSelector} from "react-redux";

const contextMenuInitialPosition: ContextMenuSchema = {
    record: undefined,
    visible: false,
    x: 0,
    y: 0
}

const columns: ProColumns<Order>[] = [
    {
        title: 'CUD',
        dataIndex: 'cud',
        copyable: true,
        width: 230,
        render: text => <b style={{ color: 'rgb(135 130 130)' }}>{text}</b>
    },
    {
        title: 'ESTADO',
        dataIndex: 'status',
        ellipsis: true,
        render: text => <b style={{ color: 'rgb(135 130 130)' }}>{text}</b>
    },
    {
        title: 'COMPRADOR',
        dataIndex: 'nombreDespacho',
        ellipsis: true
    },
    {
        title: 'FECHA GUÍA',
        dataIndex: 'fechaGuia',
        width: 120
    },
    {
        title: 'NRO. MANIFIESTO',
        dataIndex: 'manifiesto',
        ellipsis: true,
    },
    {
        title: 'TIENDA',
        dataIndex: 'tienda',
        ellipsis: true,
        width: 180
    },
    {
        title: 'VEHÍCULO',
        dataIndex: 'vehiculo',
        ellipsis: true,
        width: 180
    },
];

const Orders = (): JSX.Element => {
    const authUser = useSelector((state: any) => state.auth);
    const hiddenFileInput = useRef(null);
    const [modal, modalContextHolder] = Modal.useModal();
    const [openHistoryDrawer, setOpenHistoryDrawer] = useState(false);
    const [messageApi, messageContextHolder] = message.useMessage();
    const [openGenerateReportModal, setOpenGenerateReportModal] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState<ContextMenuSchema>(contextMenuInitialPosition);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [loading, setLoading] = useState(false);
    const ref = useRef<ActionType>();

    const openUploadOrder = async () => {
        const inputRef = hiddenFileInput as any;
        inputRef.current.click();
    }

    const uploadNewOrdersTemplate = async (files: FileList | null) => {
        if(files && files.length > 0) {
            modal.confirm({
                title: '¿Está seguro que desea cargar la plantilla?',
                icon: <ExclamationCircleOutlined />,
                content: (
                    <>
                        <em style={{ marginTop: '10px' }}>Recuerde que la plantilla debe de tener las siguientes columnas en el mismo orden indicado, de lo contrario no funcionará correctamente el proceso:</em><br/><br/>
                        <ul>
                            <li><em>Fecha de guía, código de venta, descripción, cantidad, distrito, ciudad, jornada, dni despacho, nombre despacho.</em></li>
                            <li><em>Dirección despacho, teléfono, correo, cud, nro de guía, nro de ticket, manifiesto.</em></li>
                            <li><em>Pedido, fecha de compromiso, opl, tienda, tamaño.</em></li>
                        </ul>
                    </>
                ),
                okText: 'SI',
                cancelText: 'NO',
                onOk: async () => {
                    const file = files[0];

                    setLoading(true);

                    const responseNewOrders = await OrderService.createNewOrders(file);

                    if(responseNewOrders.success) {
                        messageApi.success(`Se efectuó satisfactoriamente la carga de órdenes al sistema.`, 3.5);
                        setTimeout(() => {
                            const table = ref.current as any;
                            table.reload();
                        }, 3.5);
                    }else{
                        messageApi.error(`No se pudo realizar el proceso, asegurese de usar la plantilla e ingresar los datos correctamente.`, 3.5);
                    }

                    setLoading(false);
                    const inputRef = hiddenFileInput as any;
                    inputRef.current.value = '';
                },
                onCancel: () => {
                    const inputRef = hiddenFileInput as any;
                    inputRef.current.value = '';
                }
            });
        }
    }

    const fitToColumn = (arrayOfArray: any) => {
        return arrayOfArray[0].map((a: any, i: any) => ({ wch: Math.max(...arrayOfArray.map((a2: any) => { return a2[i] ? a2[i].toString().length : 0 } )) }));
    }

    const downloadReport = async (date: string) => {
        const responseDataReport = await OrderService.getReportData(date);
        if(responseDataReport.success) {
            messageApi.info('Descargando reporte', 3.5);

            const data: any = responseDataReport.data || [];

            let dataToExcel: any = [ [
                'CUD', 'NRO. GUÍA', 'NRO. TICKET', 'PEDIDO', 'COMPRADOR', 'DNI', 'CORREO', 'TELÉFONO', 'FECHA GUÍA', 'FECHA COMPROMISO', 'NRO. MANIFIESTO',
                'VEHÍCULO', 'TIENDA', 'DIRECCIÓN DESPACHO', 'DISTRITO', 'CIUDAD', 'DESCRIPCIÓN PRODUCTO', 'TAMAÑO', 'CANTIDAD', 'STATUS RIPLEY',
                'SUBSTATUS # 1', 'SUBSTATUS # 2', 'SUBSTATUS # 3',
            ]];

            for(const row of data) {
                const substatus1 = row.history.length >= 1 ? `[${moment(row.history[0].date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}] ${row.history[0].subStatus}` : '';
                const substatus2 = row.history.length >= 2 ? `[${moment(row.history[1].date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}] ${row.history[1].subStatus}` : '';
                const substatus3 = row.history.length >= 3 ? `[${moment(row.history[2].date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}] ${row.history[2].subStatus}` : '';


                dataToExcel.push([
                    row.cud,
                    row.nroGuia,
                    row.nroTicket,
                    row.pedido,
                    row.nombreDespacho,
                    row.dni,
                    row.correo,
                    row.telefono,
                    moment(row.fechaGuia, 'YYY-MM-DD').format('DD/MM/YYYY'),
                    moment(row.fechaDeCompromiso, 'YYY-MM-DD').format('DD/MM/YYYY'),
                    row.manifiesto,
                    row.vehicle,
                    row.tienda,
                    row.direccionDespacho,
                    row.distrito,
                    row.ciudad,
                    row.descripcion,
                    row.tamano,
                    row.cantidad,
                    row.statusRipley,
                    substatus1,
                    substatus2,
                    substatus3
                ]);
            }


            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(dataToExcel);
            worksheet['!cols'] = fitToColumn(dataToExcel);
            workbook.SheetNames.push('Reporte');
            workbook.Sheets["Reporte"] = worksheet;

            XLSX.writeFile(workbook, "reporte_"+ date.replaceAll('-', '_') +".xlsx");
        }else{
            messageApi.error('No se pudo generar el reporte, inténtelo nuevamente.', 3.5);
        }
    }

    const showHistory = (orderSelected: Order) => {
        setSelectedOrder(orderSelected);
        setOpenHistoryDrawer(true)
    };

    return (
        <>
            <ContextMenu contextMenuPosition={contextMenuPosition} showHistory={showHistory} />

            <AppLayout title="Lista de órdenes" loading={loading}>
                {modalContextHolder}
                {messageContextHolder}
                <GenerateOrderReportModal open={openGenerateReportModal} submit={downloadReport} setOpen={setOpenGenerateReportModal} />

                {
                    selectedOrder && (
                        <>
                            <HistoryDrawer open={openHistoryDrawer} setOpen={setOpenHistoryDrawer} order={selectedOrder} token={authUser.token}/>
                        </>
                    )
                }

                <ProCard
                    style={{
                        minHeight: 350,
                    }}
                >
                    <input type="file" ref={hiddenFileInput} onChange={(event) => uploadNewOrdersTemplate(event.target.files)} style={{ display: 'none' }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>

                    <ProTable<Order>
                        actionRef={ref}
                        size="small"
                        columns={columns}
                        cardBordered
                        request={async (params = {}, sort, filter) => {
                            const keyWord = (params.keyword || '').trim().toUpperCase();

                            const ordersResponse = await OrderService.getOrders(params.current || 1, params.pageSize || 12, (keyWord.length === 0 ? undefined : keyWord));

                            if(ordersResponse.success) {
                                let orders = ordersResponse.data?.orders.map((order: any, index: number) => {
                                    const history = order.history.map((historyRow: any) => {
                                        return {
                                            error: historyRow.error,
                                            date: new Date(historyRow.date),
                                            status: historyRow.status,
                                            subStatus: historyRow.subStatus,
                                            image1: historyRow.image1,
                                            image2: historyRow.image2,
                                        };
                                    });

                                    return {
                                        id: (index+1),
                                        cud: order.data[0].cud || order.data[0].orderNumber  ,
                                        status:order.status,
                                        nombreDespacho: order.data[0].nombreDespacho || order.data[0].fullName,
                                        fechaGuia: order.data[0].fechaGuia || order.data[0].deliveryDate,
                                        manifiesto: order.data[0].manifiesto,
                                        tienda: order.data[0].tienda ||order.data[0].storeId,
                                        vehiculo: order.vehicle,
                                        history: history
                                    };
                                });

                                return {
                                    success: true,
                                    data: orders,
                                    total: ordersResponse.data?.count || 0
                                };
                            }

                            return {
                                success: false,
                                data: [],
                                total: 0
                            };
                        }}
                        editable={{
                            type: 'multiple',
                        }}
                        rowKey="id"
                        search={false}
                        options={{
                            fullScreen: true,
                            reload: true,
                            setting: false,
                            density: false,
                            search: true
                        }}
                        pagination={{
                            pageSize: 12,
                            onChange: (page) => console.log(page),
                        }}
                        dateFormatter="string"
                        toolBarRender={() => [
                            <Button key="button" icon={<PlusOutlined />} type="primary" onClick={openUploadOrder}>
                                Cargar órdenes
                            </Button>,
                            <Dropdown
                                key="menu"
                                menu={{
                                    items: [
                                        {
                                            label: 'Generar reporte',
                                            key: '1',
                                            icon: <FileExcelOutlined />,
                                            onClick: () => {
                                                setOpenGenerateReportModal(true);
                                            }
                                        }
                                    ],
                                }}
                            >
                                <Button>
                                    <EllipsisOutlined />
                                </Button>
                            </Dropdown>,
                        ]}
                        onRow={(record, rowIndex) => {
                            return {
                                onContextMenu: (event) => {
                                    event.preventDefault();

                                    if(!contextMenuPosition.visible) {
                                        document.addEventListener(`click`, function onClickOutside() {
                                            setContextMenuPosition(contextMenuInitialPosition);
                                            document.removeEventListener(`click`, onClickOutside);
                                        })
                                    }
                                    setContextMenuPosition({
                                        record,
                                        visible: true,
                                        x: event.clientX,
                                        y: event.clientY
                                    });
                                },
                            };
                        }}
                    />
                </ProCard>
            </AppLayout>
        </>
    );
};

export default Orders;