import React from 'react';

import './Loading.css';

const Loading = (): JSX.Element => {
    return (
        <div id="loading-wrapper">
            <div id="loading-text">ESPERE</div>
            <div id="loading-content"></div>
        </div>
    );
};

export default Loading;