import React from "react";
import {Routes, Route, Navigate } from 'react-router-dom';
import Login from "../scenes/Login";
import GuestRoute from "./components/GuestRoute";
import PrivateRoute from "./components/PrivateRoute";
import Home from "../scenes/Home";
import NotFound from "../scenes/NotFound";
import RipleyTracking from "../scenes/RipleyTracking";
import SmuTracking from "../scenes/SmuTracking";
import RipleyOrders from "../scenes/RipleyOrders";
import Drivers from "../scenes/Drivers";
import RipleyAssignOrder from "../scenes/RipleyAssignOrder";


export default function AppRoutes() {

  return (
      <Routes>
        <Route path="/" element={<Navigate to='/home'/>} />
        <Route path="/ripley/:id" element={<RipleyTracking/>} />
        <Route path="/smu/:id" element={<SmuTracking/>} />
        <Route path="/ripley-orders" element={<PrivateRoute outlet={<RipleyOrders />} />} />
        <Route path="/ripley-assign-order" element={<PrivateRoute outlet={<RipleyAssignOrder />} />} />
        <Route path="/drivers" element={<PrivateRoute outlet={<Drivers />} />} />
        <Route path="/home" element={<PrivateRoute outlet={<Home />} />} />
        <Route path="/login" element={<GuestRoute outlet={<Login />} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
  );
}
