import React, {useEffect, useState} from 'react';
import AppLayout from "../../components/Layouts/App/App";
import {useSelector} from "react-redux";

const Index = (): JSX.Element => {
    const authUser = useSelector((state: any) => state.auth);
    const [initComponent, setInitComponent] = useState(false);

    useEffect(() => {
        const init = async () => {
            setInitComponent(true);
        };

        init();
    }, []);

    return (
        <AppLayout title={"Home"}>
            <h4>Bievenidos a la aplicación de titask</h4>
        </AppLayout>
    );
};

export default Index;