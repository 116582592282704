import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import TrackingLayout from "../../components/Layouts/Tracking/Tracking";
import {Avatar, Card, Descriptions, List, Steps} from "antd";
import {
    BranchesOutlined,
    DeliveredProcedureOutlined,
    HomeOutlined,
    SolutionOutlined
} from "@ant-design/icons";
import moment from "moment";
import {SmuTrackingService} from "./services/SmuTrackingService";

interface StepSchema {
    title: string;
    icon: React.ReactNode,
    description?: string | React.ReactNode
};

const initialStepsValue: StepSchema[] = [
    {
        title: 'Orden procesada',
        icon: <SolutionOutlined />,
    },
    {
        title: 'Enviada',
        icon: <DeliveredProcedureOutlined />
    },
    {
        title: 'En ruta',
        icon: <BranchesOutlined />
    },
    {
        title: 'Llegada del pedido',
        icon: <HomeOutlined />
    },
];

const TrackingSmu = (): JSX.Element => {
    const { id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ steps, setSteps ] = useState<StepSchema[]>(initialStepsValue);
    const [ order, setOrder ] = useState<any>(undefined);

    useEffect(() => {
        const init = async () => {
            if(id) {
                const orderResponse = await SmuTrackingService.getOrder(id);

                if(orderResponse.success) {
                    console.log("Smu =>", orderResponse)
                    setOrder(orderResponse.data);

                    let stepsForUpdate = [...steps];

                    let step = 0;

                    const step0 = obtainValueForHistory(orderResponse.data.history, ['job']);
                    const step1 = obtainValueForHistory(orderResponse.data.history, ['Shipped']);
                    const step2 = obtainValueForHistory(orderResponse.data.history,  ['In Route', 'arrived_to_destination']);
                    const step3 = obtainValueForHistory(orderResponse.data.history, ['Arrived', "delivered"]);

                    if(step0) {
                        step = 0;
                        stepsForUpdate[0].description = step0 ? moment(step0, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '';
                    }

                    if(step1) {
                        step = 1;
                        stepsForUpdate[1].description = step1 ? moment(step1, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '';
                    }

                    if(step2) {
                        step = 2;
                        stepsForUpdate[2].description = step2 ? moment(step2, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '';
                    }

                    if(step3) {
                        step = 3;
                        stepsForUpdate[3].description = step3 ? moment(step3, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '';
                    }

                    setCurrentStep(step);
                    setSteps(steps);
                }

                setLoading(false);
            }
        }

        init();
    }, []);

    const obtainValueForHistory = (data: any, values: string []) => {
        values = values.map((value) => value.toLowerCase());

        return (data.find((row: any) => values.includes(row.status.toLowerCase())))?.date;
    }

    const getDocumentType = (data: any) => {
        if(typeof data !== 'string') {
            return 'Tipo de documento';
        }

        const parts = data.split('-');
        return (parts.length >= 2 ? parts[0]: 'Tipo de documento');
    }

    const getDocumentNumber = (data: any) => {
        if(typeof data !== 'string') {
            return '';
        }

        const parts = data.split('-');
        return (parts.length >= 2 ? parts[1]: '');
    }

    return (
        <TrackingLayout loading={loading}>
            <Card bordered={false}
                  title={(<div className="card-title-tracking">
                      <span>Orden: <b style={{ color: 'red' }}>{(order?.orderClient ? order?.orderClient : (loading ? 'CARGANDO....' : 'NO ENCONTRADA'))}</b></span>
                      <span>Vehículo: <b>{(order?.vehicle ? order?.vehicle.toUpperCase() : '')}</b></span>
                      <span>Fecha estimada: <b>--/--/----</b></span>
                      <span>Status: <b>{(order?.status ? order?.status.toUpperCase() : 'CREADO')}</b></span>
                  </div>)}
            >
                <Steps
                    current={currentStep}
                    items={steps}
                />

                <List<any>
                    size="small"
                    style={{ marginTop: '25px' }}
                    header={<b style={{ color: 'rgb(135 130 130)' }}>Detalle de productos</b>}
                    footer={false}
                    bordered
                    dataSource={(order?.items ? order?.items : [])}
                    pagination={{
                        onChange: (page) => {
                            console.log(page);
                        },
                        pageSize: 3,
                    }}
                    renderItem={(item: any) => (
                        <List.Item>
                            <List.Item.Meta
                                className="tracking-item-list-antd"
                                avatar={<Avatar style={{ marginTop: '4px' }} src={item.photo_url} />}
                                title={`${item.name}`}
                                description={`📦 Cantidad: ${item.quantity} 🏷 Precio: ${item.price}`}
                            />

                        </List.Item>
                    )}
                />

                <List
                    size="small"
                    style={{ marginTop: '25px' }}
                    header={<b style={{ color: 'rgb(135 130 130)' }}>Detalle del comprador</b>}
                    footer={false}
                    bordered
                    dataSource={["dummyItem"]}
                    renderItem={(item: string) => (
                        <List.Item>
                            <Descriptions title={false} layout="vertical">
                                <Descriptions.Item label="Dirección">{order?.address}</Descriptions.Item>
                                <Descriptions.Item label="Tienda">{order?.store}</Descriptions.Item>
                                <Descriptions.Item label="Teléfono">{order?.user?.phoneNumber}</Descriptions.Item>
                                <Descriptions.Item label="Nombre">{order?.user?.name}</Descriptions.Item>
                                <Descriptions.Item label={getDocumentType(order?.user?.identificationNumber)}>{getDocumentNumber(order?.user?.identificationNumber)}</Descriptions.Item>
                                <Descriptions.Item label="Correo">{order?.user?.email}</Descriptions.Item>
                            </Descriptions>
                        </List.Item>
                    )}
                />
            </Card>
        </TrackingLayout>
    );
};

export default TrackingSmu;