import React from 'react';
import {HistoryOutlined, SearchOutlined} from "@ant-design/icons";
import {ContextMenuSchema} from "../../../models/ContextMenuSchema";
import {Order} from "../models/Order";

import './ContextMenu.css';

const ContextMenu = ({ contextMenuPosition, showHistory }: { contextMenuPosition: ContextMenuSchema<Order>, showHistory: (record: Order) => void }): JSX.Element => {
    const { record, visible, x, y } = contextMenuPosition;

    return (
        <>
            {
                (visible && record) && (
                    <ul className="popupcm" style={{left: `${x}px`, top: `${y}px`, padding: '5px'}}>
                        <span>Orden: <b>{record.cud}</b></span>
                        <hr style={{ border: '0', borderTop: '1px solid #c3c3c3'}}/>
                        <li onClick={() => { showHistory(record) }}><HistoryOutlined style={{ marginRight: '3px' }} /> Ver historial</li>
                    </ul>
                )
            }
        </>
    );
};

export default ContextMenu;