import axios from "axios";
import { config } from "../../../config/config";

const apiIntegrations = axios.create({
    baseURL: config.API_INTEGRATIONS_URI,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": config.API_INTEGRATIONS_TOKEN,
        "server": "prd"
    },
})

export const OrderService = {
    getOrders: async (currentPage: number, pageSize: number = 12, order?: string) => {
        try {
            const response = await apiIntegrations.post(`/drivin/orders-ripley/${currentPage}/${pageSize}`, { orderId: order });

            return {
                success: true,
                data: {
                    count: response.data.count || 0,
                    orders: response.data.orders || []
                }
            }
        } catch (err) {
            return {
                success: false,
                error: err
            }
        }
    },
    getReportData: async (date: string) => {
        try {
            const response = await apiIntegrations.post('/drivin/orderReport/ripley', { fecha: date });
            return {
                success: true,
                data: response.data.response
            }
        } catch (err) {
            return {
                success: false,
                error: err
            }
        }
    },
    createNewOrders: async (file: File) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            const response = await apiIntegrations.post('/drivin/create-orders-csv/ripley', formData, config);

            return {
                success: true,
                data: response.data
            }
        } catch (err) {
            return {
                success: false,
                error: err
            }
        }
    }
};