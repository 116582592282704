import axios from "axios";
import {config} from "../../../config/config";

const apiIntegrations = axios.create({
    baseURL: config.API_INTEGRATIONS_URI,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "server": "prd",
        "Authorization": "6HrfHg8Z2TT9wT9FKQu4"
    },
})

export const RipleyTrackingService  = {
    getOrder: async (orderNro: string) => {
        try {
            const response = await apiIntegrations.get(`/drivin/order-satus-ripley/${orderNro}`);

            const dataRaw = response.data.response;

            return {
                success: true,
                data: dataRaw
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    }
};