import React, {useEffect, useState} from 'react';
import {ProForm, ProFormSelect} from "@ant-design/pro-components";
import {Modal} from "antd";
import User from "../../../models/User";
import {AvailableOrder} from "../models/AvailableOrder";

interface AssignDriverModalSchema {
    open: boolean;
    submit: (driver: string, documentDriver: string | null, vehicle: string | null) => void;
    setOpen: (value: boolean) => void;
    drivers: User[];
    orderIds: React.Key[];
    orders: AvailableOrder[];
}

const AssignDriverModal = ({open, submit, setOpen, orderIds, drivers, orders}: AssignDriverModalSchema): JSX.Element => {
    const [ordersSelectedString, setOrdersSelectedString] = useState('');


    const getDriverOptions = () => {
        return drivers.map((driver) => {
            return {
                value: driver.id,
                label: `${driver.name} ${driver.lastname} [Placa: ${driver.plateNumber}]`
            };
        });
    }

    useEffect(() => {
        const selectedOrders = orders.filter((order) => {
            return orderIds.includes(order.cud);
        });

        setOrdersSelectedString(selectedOrders.map((order) => order.cud).join(', '));
    }, [orders, orderIds]);


    return (
        <Modal
            title="Asignar driver"
            open={open}
            destroyOnClose
            onCancel={() => { setOpen(false) }}
            closable
            keyboard
            footer={null}
        >
            <hr style={{ border: '0', borderTop: '1px solid #c3c3c3'}}/>

            <ProForm<any>
                style={{ marginTop: '20px' }}
                onFinish={async (values: any) => {
                    const driverId = values.driver;
                    const driverSelected = drivers.find((driver) => {
                        return driver.id === driverId;
                    });

                    if(driverSelected) {
                        const driverName = driverSelected.name;
                        const documentDriver = driverSelected.documentNumber;
                        const vehicle = driverSelected.plateNumber;
                        await submit(driverName, documentDriver, vehicle);
                    }

                }}
                submitter={{
                    searchConfig: {
                        submitText: 'Asignar'
                    },
                    resetButtonProps: {
                        style: {
                            display: 'none',
                        },
                    },
                    submitButtonProps: {
                        style: {
                            marginLeft: '-8px',
                            marginTop: '10px'
                        }
                    },
                }}
            >
                <ProForm.Group>
                    <ProFormSelect
                        options={getDriverOptions()}
                        width="xl"
                        name="driver"
                        label="Driver"
                        rules={[{ required: true, message: 'Debe de seleccionar el Driver' }]}
                    />

                    <span><b>{ orderIds.length === 1 ? 'Órden seleccionada' : 'Órdenes seleccionadas' }:</b> <em>{ordersSelectedString}</em></span>
                </ProForm.Group>
            </ProForm>
        </Modal>
    );
};

export default AssignDriverModal;