import React from 'react';
import { Result } from 'antd';

const Unauthorized = () => {
    return (
        <Result
            style={{ marginTop: '40px' }}
            status="403"
            title="403"
            subTitle="Lo sentimos, no está autorizado para acceder a esta página."
        />
    )
}

export default Unauthorized;
