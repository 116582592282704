import { combineReducers } from 'redux';
import { createRouterReducer } from '@lagunovsky/redux-react-router';
import authReducer from './authReducers';

const rootReducer = (history: any) =>
    combineReducers({
        auth: authReducer,
        router: createRouterReducer(history),
    });

export default rootReducer;
