import {config} from "../../../config/config";
import User from "../../../models/User";
import axios from "axios";

const api = axios.create({
    baseURL: config.API_BFF_TITASK_APP,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "lang": "es"
    },
})

export const DriverService = {
    findDriverById: async (token: string, id: number) => {
        try {
            const response = await api.get(`/app-user/find-app-user/${id}`,
                {  headers: { Authorization: `Bearer ${token}` },
                });

            const userRawData = response.data.data;

            return {
                success: true,
                data: {
                    id: userRawData.id,
                    appRoleId: userRawData.appRoleId,
                    email: userRawData.email,
                    name: userRawData.name,
                    lastname: userRawData.lastname,
                    documentType: userRawData.documentType,
                    documentNumber: userRawData.documentNumber,
                    platform: userRawData.platform,
                    plateNumber: userRawData.plateNumber,
                    token: userRawData.token
                } as User
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    getDrivers: async (token: string) => {
        try {
            const response = await api.post(`/app-user/get-ripley-users`,
                { roleIds: ['DRIVER_RIPLEY'] },
                {  headers: { Authorization: `Bearer ${token}` },
            });

            const driversRawData = response.data.data;

            return {
                success: true,
                data: driversRawData.map((driverRawData: any): User => {
                    return {
                        id: driverRawData.id,
                        appRoleId: driverRawData.appRoleId,
                        email: driverRawData.email,
                        name: driverRawData.name,
                        lastname: driverRawData.lastname,
                        documentType: driverRawData.documentType,
                        documentNumber: driverRawData.documentNumber,
                        platform: driverRawData.platform,
                        plateNumber: driverRawData.plateNumber,
                        token: driverRawData.token,
                        carriers: !driverRawData.carriers ? [] : driverRawData.carriers.map((carrier: any) => carrier.id)
                    }
                })
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    createDriver: async (token: string, email: string, name: string, lastname: string, documentType: string, documentNumber: string, plateNumber: string) => {
        try {
            const response = await api.post(`/app-user/create-ripley-user`,
                { email, name, lastname, documentType, documentNumber, plateNumber, roleId: 'DRIVER_RIPLEY' },
                {  headers: { Authorization: `Bearer ${token}` },
                });

            return {
                success: true,
                data: response.data.data
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    updateDriver: async (token: string, id: number, name: string, lastname: string, documentType: string, documentNumber: string, plateNumber: string) => {
        try {
            const response = await api.put(`/app-user/update-ripley-user/${id}`,
                { name, lastname, documentType, documentNumber, plateNumber, roleId: 'DRIVER_RIPLEY' },
                {  headers: { Authorization: `Bearer ${token}` },
                });

            return {
                success: true,
                data: response.data.data
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    checkEmailExistence: async (token: string, email: string) => {
        try {
            const response = await api.put(`/app-user/check-email-existence`,
                { email },
                {  headers: { Authorization: `Bearer ${token}` },
                });

            return {
                success: true,
                data: response.data.data
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
}