import axios from "axios";
import {config} from "../config/config";

const api = axios.create({
    baseURL: config.API_BFF_TITASK_APP,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "lang": "es"
    },
})

export const FileService  = {
    getFileByKey: async (token: string, key: string) => {
        try {
            const response = await api.post(`/file`, { key }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            return {
                success: true,
                data: response.data.data?.base64Encoded || ''
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
};