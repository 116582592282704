import React from 'react';
import {Spin, ConfigProvider, Layout} from 'antd';
import esESIntl from 'antd/es/locale/es_ES';
import {Content} from "antd/es/layout/layout";

import './Tracking.css';

const TrackingLayout = ({ children, loading }: { children: React.ReactNode, loading?: boolean }): JSX.Element => {
    const PRIMARY_COLOR = '#00aa80';

    return (
        <Spin spinning={!!loading}>
            <div
                style={{
                    height: '100vh',
                    backgroundColor: PRIMARY_COLOR
                }}
            >
                <ConfigProvider locale={esESIntl}
                    theme={{
                        token: {
                            colorPrimary: PRIMARY_COLOR,
                        },
                    }}
                >
                    <Layout style={{ backgroundColor: PRIMARY_COLOR }}>
                        <Content>
                            <div className="container">
                                <div className="iconTitleDiv">
                                    <img src="https://titaskdomain.s3.us-east-2.amazonaws.com/files/images/titask_blaco.png" alt="Titask Logo" className="iconTitle" style={{ width: '220px' }}/>
                                </div>
                                {children}
                            </div>
                        </Content>
                    </Layout>
                </ConfigProvider>
            </div>
        </Spin>
    );
};

export default TrackingLayout;