import axios from "axios";
import {config} from "../config/config";
import User from "../models/User";
import {Permission} from "../models/Permission";

const api = axios.create({
    baseURL: config.API_BFF_TITASK_APP,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "lang": "es"
    },
})

export const AuthService  = {
    verifySession: async (token: string) => {
        try {
            const response = await api.get(`/app-auth/auth`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const userData = response.data.data;

            return {
                success: true,
                data: {
                    id: userData.id,
                    appRoleId: userData.appRoleId,
                    email: userData.email,
                    name: userData.name,
                    lastname: userData.lastname,
                    documentType: userData.documentType,
                    documentNumber: userData.documentNumber,
                    platform: userData.platform,
                    plateNumber: userData.plateNumber,
                    token: userData.token,
                    permissions: userData.permissions.map((permission: any): Permission => {
                        return {
                            id: permission.id,
                            titleEn: permission.titleEn,
                            titleEs: permission.titleEs,
                            platform: permission.platform,
                            description: permission.description
                        };
                    }),
                    carriers: !userData.carriers ? [] : userData.carriers.map((carrier: any) => carrier.id)
                } as User
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    logout: async (token: string) => {
        try {
            const response = await api.get('/app-auth/logout', { headers: { Authorization: `Bearer ${token}` } });
            console.log("response logout", response)
            return {
                success: true
            }
        }catch (err)
        {
            return {
                success: false,
                error: err
            }
        }
    },
};