import User from "../../../models/User";
import {SIGN_IN, SIGN_OUT, UPDATE_AUTH_INFO} from "../../actions/authActions/actionTypes";
import {IAction} from "../../models/IAction";

const defaultStore: User = {
    id: 0,
    appRoleId: '',
    email: '',
    name: '',
    lastname: '',
    documentType: null,
    documentNumber:  null,
    platform: '',
    plateNumber: null,
    token: '',
    permissions: [],
    carriers: []
};

export default function user(state: User = defaultStore, action: IAction) {
    switch (action.type) {
        case SIGN_IN:
            return {
                id: action.payload.id,
                appRoleId: action.payload.appRoleId,
                email: action.payload.email,
                name: action.payload.name,
                lastname: action.payload.lastname,
                documentType: action.payload.documentType,
                documentNumber: action.payload.documentNumber,
                platform: action.payload.platform,
                plateNumber: action.payload.plateNumber,
                token: action.payload.token,
                permissions: action.payload.permissions,
                carriers: action.payload.carriers
            };
        case UPDATE_AUTH_INFO:
            return {
                ...state,
                ...action.payload,
            };
        case SIGN_OUT:
            return {
                ...state,
                ...defaultStore,
            };
        default:
            return state;
    }
}
