import axios from "axios";
import { config } from "../../../config/config";
import User from "../../../models/User";
import { AvailableOrder } from "../models/AvailableOrder";

const api = axios.create({
    baseURL: config.API_BFF_TITASK_APP,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "lang": "es"
    },
})

const apiIntegrations = axios.create({
    baseURL: config.API_INTEGRATIONS_URI,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": config.API_INTEGRATIONS_TOKEN,
        "server": "prd"
    },
})

export const AssignOrderService = {
    getDrivers: async (token: string) => {
        try {
            const response = await api.post(`/app-user/get-ripley-users`,
                { roleIds: ['DRIVER_RIPLEY'] },
                {
                    headers: { Authorization: `Bearer ${token}` },
                });

            const driversRawData = response.data.data;

            return {
                success: true,
                data: driversRawData.map((driverRawData: any): User => {
                    return {
                        id: driverRawData.id,
                        appRoleId: driverRawData.appRoleId,
                        email: driverRawData.email,
                        name: driverRawData.name,
                        lastname: driverRawData.lastname,
                        documentType: driverRawData.documentType,
                        documentNumber: driverRawData.documentNumber,
                        platform: driverRawData.platform,
                        plateNumber: driverRawData.plateNumber,
                        token: driverRawData.token,
                        carriers: !driverRawData.carriers ? [] : driverRawData.carriers.map((carrier: any) => carrier.id)
                    }
                })
            }
        } catch (err: any) {
            return {
                success: false,
                error: err
            }
        }
    },
    getAvailableOrders: async () => {
        try {
            const response = await apiIntegrations.get(`/drivin/orders-available`);
            return {
                success: true,
                data: response.data.orders.map((orderRaw: any): AvailableOrder => {
                    return {
                        cud: orderRaw.orderId,
                        codVenta: orderRaw.data?.length > 0 ? orderRaw.data[0].codVenta : '',
                        status: orderRaw.status.charAt(0).toUpperCase() + orderRaw.status.slice(1),
                        store: orderRaw.data?.length > 0 ? orderRaw.data[0].tienda ?? orderRaw.data[0].storeId : '',
                        vehicle: orderRaw.vehicle,
                        dni: orderRaw.data?.length > 0 ? orderRaw.data[0].dni : '',
                        fechaGuia: orderRaw.data?.length > 0 ? orderRaw.data[0].fechaGuia  ??orderRaw.data[0].deliveryDate : '',
                        distrito: orderRaw.data?.length > 0 ? orderRaw.data[0].distrito ??orderRaw.data[0].district : '',

                    };
                })
            }
        } catch (err) {
            return {
                success: false,
                error: err
            }
        }
    },
    assignDriver: async (order: string, driver: string, documentDriver: string, vehicle: string) => {
        try {
            await apiIntegrations.put(`/drivin/asing-order/${order}`, { nameVehicle: driver, documentVehicle: documentDriver, vehicle });
            return {
                success: true
            }
        } catch (err) {
            return {
                success: false,
                error: err
            }
        }
    }
}