import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {hasPermissions} from "../../utils/permissions";
import Unauthorized from "../../scenes/Unauthorized";

type PrivateRouteProps = {
  outlet: JSX.Element;
  permissionsRequired?: string | string[];
};

function PrivateRoute({ outlet, permissionsRequired = [] }: PrivateRouteProps) {
  const user = useSelector((state: any) => state.auth);

  if (user?.id !== 0 && hasPermissions(permissionsRequired, user)) {
    return outlet;
  } else if (user?.id !== 0 && !hasPermissions(permissionsRequired, user)) {
    return <Unauthorized />;
  }
  return <Navigate to="/login" />;
}

export default PrivateRoute;
