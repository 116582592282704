import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import TrackingLayout from "../../components/Layouts/Tracking/Tracking";
import {Card, Descriptions, List, Steps} from "antd";
import {
    BranchesOutlined,
    DeliveredProcedureOutlined,
    HomeOutlined,
    SolutionOutlined
} from "@ant-design/icons";
import moment from "moment";
import {RipleyTrackingService} from "./services/RipleyTrackingService";

interface StepSchema {
    title: string;
    icon: React.ReactNode,
    description?: string | React.ReactNode
};

const initialStepsValue: StepSchema[] = [
    {
        title: 'Orden procesada',
        icon: <SolutionOutlined />,
    },
    {
        title: 'Enviada',
        icon: <DeliveredProcedureOutlined />
    },
    {
        title: 'En ruta',
        icon: <BranchesOutlined />
    },
    {
        title: 'Llegada del pedido',
        icon: <HomeOutlined />
    },
];

const TrackingRipley = (): JSX.Element => {
    const { id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ steps, setSteps ] = useState<StepSchema[]>(initialStepsValue);
    const [ header, setHeader ] = useState<any>(undefined);
    const [ order, setOrder ] = useState<any>(undefined);
    const [ products, setProducts ] = useState<any[]>([]);

    useEffect(() => {
        const init = async () => {
            if(id) {
                const orderResponse = await RipleyTrackingService.getOrder(id);

                if(orderResponse.success) {
                    if(orderResponse.data?.data?.length > 0) {
                        setHeader(orderResponse.data);
                        setOrder(orderResponse.data?.data[0]);
                        setProducts(orderResponse.data?.data);

                        let stepsForUpdate = [...steps];

                        stepsForUpdate[0].description = (orderResponse.data?.data[0] ? moment(orderResponse.data?.data[0].fechaGuia, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' 06:00:00' : undefined);


                        let step = 0;

                        const step1 = obtainValueForHistory(orderResponse.data.history, 'En el Hub o Almacén del Operador');
                        const step2 = obtainValueForHistory(orderResponse.data.history, 'En Ruta hacia el Cliente');
                        const step3 = obtainValueForHistory(orderResponse.data.history, 'Entrega Exitosa');

                        if(step1) {
                            step = 1;
                            stepsForUpdate[1].description = moment(step1, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                        }

                        if(step2) {
                            step = 2;
                            stepsForUpdate[2].description = moment(step2, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                        }

                        if(step3) {
                            step = 3;
                            stepsForUpdate[3].description = moment(step3, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                        }

                        setCurrentStep(step);
                    }
                }

                setLoading(false);
            }
        }

        init();
    }, []);

    const obtainValueForHistory = (data: any, value: any) => {
        return data.find((valor: any) => valor.subStatus === value)?.date
    }

    return (
        <TrackingLayout loading={loading}>
            <Card bordered={false}
                title={(<div className="card-title-tracking">
                    <span>Orden: <b style={{ color: 'red' }}>{(header?.orderId ? header?.orderId : (loading ? 'CARGANDO....' : 'NO ENCONTRADA'))}</b></span>
                    <span>Vehículo: <b>{header?.vehicle}</b></span>
                    <span>Fecha estimada: <b>{(order?.fechaDeCompromiso ? moment(order?.fechaDeCompromiso, 'YYYY-MM-DD').format('DD/MM/YYYY') : '')}</b></span>
                    <span>Status: <b>{order?.statusRipley}</b></span>
                </div>)}
            >
                <Steps
                    current={currentStep}
                    items={steps}
                />

                <List<any>
                    size="small"
                    style={{ marginTop: '25px' }}
                    header={<b style={{ color: 'rgb(135 130 130)' }}>Detalle de productos</b>}
                    footer={false}
                    bordered
                    dataSource={products}
                    pagination={false}
                    renderItem={(item: any) => (
                        <List.Item>
                            <List.Item.Meta
                                title={`${item.descripcion}`}
                                description={<>{item.cud} - ({item.statusRipley || 'CREADO'})</>}
                            />

                        </List.Item>
                    )}
                />

                <List
                    size="small"
                    style={{ marginTop: '25px' }}
                    header={<b style={{ color: 'rgb(135 130 130)' }}>Detalle del comprador</b>}
                    footer={false}
                    bordered
                    dataSource={["dummyItem"]}
                    renderItem={(item: string) => (
                        <List.Item>
                            <Descriptions title={false} layout="vertical">
                                <Descriptions.Item label="Nombre">{order?.nombreDespacho}</Descriptions.Item>
                                <Descriptions.Item label="Tipo y número de documento">DNI - {order?.dni}</Descriptions.Item>
                                <Descriptions.Item label="Correo">{order?.correo}</Descriptions.Item>
                                <Descriptions.Item label="Dirección">{order?.direccionDespacho}</Descriptions.Item>
                                <Descriptions.Item label="Teléfono">{order?.telefono}</Descriptions.Item>
                            </Descriptions>
                        </List.Item>
                    )}
                />
            </Card>
        </TrackingLayout>
    );
};

export default TrackingRipley;