import {HomeOutlined, DropboxOutlined, SettingOutlined, FileDoneOutlined} from "@ant-design/icons";
import React from "react";
import User from "../models/User";

const dashboardMenu = (user?: User) => {
    let routes = [
        {
            path: '/home',
            name: 'Home',
            icon: <HomeOutlined />,
        },
        {
            path: '/ripley-orders',
            name: 'Órdenes',
            icon: <DropboxOutlined />,
            permission: 'SHOW_RIPLEY_ORDER_HISTORY_MODULE'
        },
        {
            path: '/ripley-assign-order',
            name: 'Asignar orden',
            icon: <FileDoneOutlined />,
            permission: 'SHOW_RIPLEY_ASSIGN_ORDER_MODULE'
        },
        {
            path: '/drivers',
            name: 'Drivers',
            icon: <SettingOutlined />,
            permission: 'SHOW_RIPLEY_DRIVER_MAINTENANCE_MODULE'
        },
    ];

    routes = routes.filter((route: any) => {
        const permissions: any[] = user ? (user.permissions ? user.permissions.map((permission) => permission.id) : []) : [];
        return !route.permission ||  permissions.includes(route.permission);
    });

    return {
        routes: [
            {
                path: '/',
                name: 'Titask',
                routes: routes
            }
        ]
    };
};

export default dashboardMenu;