import React from 'react';
import {HistoryOutlined, SearchOutlined} from "@ant-design/icons";
import User from "../../../models/User";
import {ContextMenuSchema} from "../../../models/ContextMenuSchema";

import './DriverContextMenu.css';

const DriverContextMenu = ({ contextMenuPosition, editDriver }: { contextMenuPosition: ContextMenuSchema<User>, editDriver: (record: User, disabled: boolean) => void}): JSX.Element => {
    const { record, visible, x, y } = contextMenuPosition;

    return (
        <>
            {
                (visible && record) && (
                    <ul className="popupcm" style={{left: `${x}px`, top: `${y}px`, padding: '5px'}}>
                        <span>Email: <b>{record.email}</b></span>
                        <hr style={{ border: '0', borderTop: '1px solid #c3c3c3'}}/>
                        <li onClick={() => { editDriver(record, true) }}><SearchOutlined style={{ marginRight: '3px' }} /> Ver</li>
                        <li onClick={() => { editDriver(record, false) }}><HistoryOutlined style={{ marginRight: '3px' }} /> Editar</li>
                    </ul>
                )
            }
        </>
    );
};

export default DriverContextMenu;