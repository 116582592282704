import React from 'react';
import {
    ProForm,
    ProFormDatePicker,
} from '@ant-design/pro-components';
import { Modal } from 'antd';
import moment from "moment";

interface GenerateOrderReportModalSchema {
    open: boolean;
    submit: (date: string) => void;
    setOpen: (value: boolean) => void;
}

const GenerateOrderReportModal = ({open, submit, setOpen}: GenerateOrderReportModalSchema): JSX.Element => {
    return (
        <Modal
            title="Generar reporte"
            open={open}
            destroyOnClose
            onCancel={() => { setOpen(false) }}
            closable
            keyboard
            footer={null}
        >
            <hr style={{ border: '0', borderTop: '1px solid #c3c3c3'}}/>

            <ProForm<any>
                style={{ marginTop: '20px' }}
                onFinish={async (values: any) => {
                    await submit(values.date);
                }}
                submitter={{
                    searchConfig: {
                        submitText: 'Descargar reporte'
                    },
                    resetButtonProps: {
                        style: {
                            display: 'none',
                        },
                    },
                    submitButtonProps: {
                        style: {
                            marginLeft: '-8px',
                        }
                    },
                }}
            >
                <ProForm.Group>
                    <ProFormDatePicker
                        width="lg"
                        label="Ingrese la fecha límite"
                        name="date_input"
                        transform={(value) => {
                            return {
                                date: moment(value).format('YYYY-MM-DD'),
                            };
                        }}
                        rules={[{ required: true, message: 'Debe de ingresar la fecha límite' }]}
                    />
                </ProForm.Group>
            </ProForm>
        </Modal>
    );
};

export default GenerateOrderReportModal;