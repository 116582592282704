import React, { useEffect, useRef, useState } from 'react';
import AppLayout from "../../components/Layouts/App/App";
import User from "../../models/User";
import { AssignOrderService } from "./services/AssignOrderService";
import { useSelector } from "react-redux";
import { Button, message } from "antd";
import { ActionType, ProCard, ProColumns, ProTable } from "@ant-design/pro-components";
import { AvailableOrder } from "./models/AvailableOrder";
import AssignDriverModal from "./components/AssignDriverModal";

const columns: ProColumns<AvailableOrder>[] = [
    {
        title: 'CUD',
        dataIndex: 'cud',
        width: 240,
        copyable: true,
        render: text => <b style={{ color: 'rgb(135 130 130)' }}>{text}</b>
    },
    {
        title: 'FCH.GUIA',
        dataIndex: 'fechaGuia',
        width: 150,
        copyable: false,
    },
    {
        title: 'COD. VENTA',
        dataIndex: 'codVenta',
        ellipsis: true,
        width: 180,
    },
    {
        title: 'VEHÍCULO',
        dataIndex: 'vehicle',
        ellipsis: true,
        width: 150
    },

    {
        title: 'TIENDA',
        dataIndex: 'store',
        ellipsis: true,
        width: 200
    },
    {
        title: 'Destino',
        dataIndex: 'distrito',
        ellipsis: true,
        width: 300
    },
    {
        title: 'STATUS',
        dataIndex: 'status',
        ellipsis: true,
        width: 150,
    },
];

const SmuAssignOrder = (): JSX.Element => {
    const [messageApi, messageContextHolder] = message.useMessage();
    const authUser = useSelector((state: any) => state.auth);
    const [drivers, setDrivers] = useState<User[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [openAssignDriverModal, setOpenAssignDriverModal] = useState(false);
    const ref = useRef<ActionType>();
    const [initState, setInitState] = useState(false);
    const [orders, setOrders] = useState<AvailableOrder[]>([]);

    useEffect(() => {
        setSelectedRowKeys([]);

        const init = async () => {

            const driversResponse = await AssignOrderService.getDrivers(authUser.token);

            if (driversResponse.success) {
                setDrivers(driversResponse.data);
            } else {
                messageApi.error(driversResponse.error?.response?.data?.data?.message || 'Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
            }

            setInitState(true);
        }

        init();
    }, []);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const getCheckboxProps = (record: AvailableOrder) => {
        return {
            disabled: false,
            key: record.cud
        };
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps
    };

    const hasSelected = selectedRowKeys.length > 0;

    const assignDriverProcess = async (driver: string | null, documentDriver: string | null, vehicle: string | null) => {

        if (!driver) {
            messageApi.error('Debe de seleccionar a un driver.', 3.5);
            return;
        }

        if (!documentDriver) {
            messageApi.error('El usuario seleccionado no presenta registrado el número de documento.', 3.5);
            return;
        }

        if (!vehicle) {
            messageApi.error('El usuario seleccionado no presenta registrado el número de placa del vehículo.', 3.5);
            return;
        }

        const promises = selectedRowKeys.map((key) => {
            return AssignOrderService.assignDriver(`${key}`, driver, documentDriver, vehicle)
        });

        if (promises.length > 0) {
            const results = await Promise.all(promises);
            const invalidResults = results.filter(result => !result.success);

            if (invalidResults.length > 0) {
                console.error(invalidResults);
                messageApi.error('Ups, ocurrió un error inesperado, por favor inténtelo nuevamente.', 3.5);
            } else {
                messageApi.success('Se realizó el proceso satisfactoriamente.', 3.5);
                const table = ref.current as any;
                table.reload();
                setOpenAssignDriverModal(false);
                setSelectedRowKeys([]);
            }
        }
    }

    return (
        <AppLayout title="Lista de órdenes pendientes de asignación">
            {messageContextHolder}

            <AssignDriverModal
                open={openAssignDriverModal}
                submit={assignDriverProcess}
                setOpen={setOpenAssignDriverModal}
                drivers={drivers}
                orderIds={selectedRowKeys}
                orders={orders}
            />


            <ProCard
                style={{
                    minHeight: 350,
                }}
            >
                <ProTable<AvailableOrder>
                    actionRef={ref}
                    rowSelection={rowSelection}
                    size="small"
                    columns={columns}
                    cardBordered
                    request={async (params = {}, sort, filter) => {
                        const keyWord = (params.keyword || '').trim().toUpperCase();

                        const ordersResponse = await AssignOrderService.getAvailableOrders();

                        if (ordersResponse.success) {

                            let orders = ordersResponse.data || [];
                            
                            if (keyWord) {
                                const tip=keyWord?.split(',');
                                console.log(tip)
                                orders = orders.filter((order: AvailableOrder) => {
                                    console.log( tip.includes(order.cud))
                                    return tip.includes(order.cud)})
                            }
                            console.log(orders)
                            setOrders(orders);

                            return {
                                success: true,
                                data: orders,
                                total: orders.length
                            };
                        }

                        setOrders([]);

                        return {
                            success: false,
                            data: [],
                            total: 0
                        };
                    }}
                    editable={{
                        type: 'multiple',
                    }}
                    rowKey="cud"
                    search={false}
                    options={{
                        fullScreen: true,
                        reload: true,
                        setting: false,
                        density: false,
                        search: true
                    }}
                    pagination={{
                        pageSize: 20,
                        onChange: (page) => console.log(page),
                    }}
                    dateFormatter="string"
                    toolBarRender={() => [
                        <Button key="button" type="primary" onClick={() => { setOpenAssignDriverModal(true) }} disabled={!hasSelected}>
                            Asignar driver
                        </Button>
                    ]}
                    scroll={{ x: 600 }}
                />
            </ProCard>
        </AppLayout>
    );
};

export default SmuAssignOrder;