import React from 'react';
import { Result } from 'antd';

const NotFound = () => {
  return (
      <Result
          style={{ marginTop: '40px' }}
          status="404"
          title="404"
          subTitle="Lo sentimos, la página que visitaste no existe."
      />
  )
}

export default NotFound;
